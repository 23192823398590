import PropTypes from 'prop-types';
import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import { DropdownOption } from '../../types';
import DeleteStrategy from './DeleteStrategy';
import { withTranslation } from 'react-i18next';

class ModalForm extends React.Component {
  reset = () => {
    if (this.props.modal.isDeleteRunning) return false;
    this.props.resetAccountForm();
  };

  render() {
    const { t } = this.props;

    return (
      <Modal
        closeIcon
        size="small"
        className="account-form"
        open={this.props.modal.isOpen}
        onClose={this.reset}
      >
        <Header
          icon="file text outline"
          content={this.props.isEdit ? t('accounts.edit_account') : t('accounts.new_account')}
        />
        <Modal.Content>
          {this.props.modal.isDeleteRequest ? (
            <DeleteStrategy {...this.props} />
          ) : (
            <this.props.EditForm />
          )}
        </Modal.Content>
        <Modal.Actions>{this.renderModalActions()}</Modal.Actions>
      </Modal>
    );
  }

  renderModalActions() {
    if (!this.props.isEdit) return;

    const { t } = this.props;

    return this.props.modal.isDeleteRequest ? (
      <Button
        labelPosition="left"
        disabled={this.props.modal.isDeleteRunning}
        onClick={this.props.removeAccountRequest}
        content={t('buttons.btn_cancel')}
        icon="cancel"
      />
    ) : (
      <Button
        negative
        labelPosition="right"
        onClick={this.props.removeAccountRequest}
        content={t("buttons.btn_delete")}
        icon="trash"
      />
    );
  }
}

ModalForm.propTypes = {
  modal: PropTypes.shape({
    isOpen: PropTypes.bool,
    isDeleteRequest: PropTypes.bool,
    isDeleteRunning: PropTypes.bool,
    itemsToProcess: PropTypes.number,
    itemsProcessed: PropTypes.number
  }),
  isEdit: PropTypes.bool,
  resetAccountForm: PropTypes.func,
  removeAccountRequest: PropTypes.func,
  removeAccount: PropTypes.func,
  EditForm: PropTypes.func,
  accountOptions: PropTypes.arrayOf(DropdownOption).isRequired
};

export default withTranslation() (ModalForm);
