import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';


const ModalForm = props => {
  const { t } = useTranslation();

  return(
    <Modal
      closeIcon
      size="small"
      className="transaction"
      open={props.isOpen}
      onClose={props.resetTransactionForm}
    >
      <Header
        icon="file text outline"
        content={props.isEdit ? t('transaction.edit_transaction') : t('dashboard.new_transaction')}
        textAlign={"right"}
      />
      <Modal.Content>
        <props.EditForm />
      </Modal.Content>
      {props.isEdit && (
        <Modal.Actions>
          <Button
            negative
            icon="trash"
            content={t('buttons.btn_delete')}
            labelPosition="right"
            onClick={() => props.removeTransaction(props.currentTransactionId)}
          />
        </Modal.Actions>
      )}
    </Modal>
  );
}

ModalForm.propTypes = {
  currentTransactionId: PropTypes.string,
  isOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  resetTransactionForm: PropTypes.func,
  removeTransaction: PropTypes.func,
  EditForm: PropTypes.func
};

export default ModalForm;
