import { getCurrentUserInfo } from '../util/api';
import {store} from '../index';
import _ from "lodash";
import { loadSubscriptionsSuccess } from '../actions/subscriptions';

const getUserInfo = () => JSON.parse(localStorage.getItem("userInfo"));

const getSetSubscription = async () => {
  const userInfo = getUserInfo();

  if(!userInfo) return;

  const [err, res] = await getCurrentUserInfo();

  if(err?.response?.status === 401) {
    localStorage.removeItem("userInfo");
    window.location.href = "/login";
    return;
  }

  userInfo.subscriptions = res?.subscriptions;
  store.dispatch({type: loadSubscriptionsSuccess, payload: res?.subscriptions});

  localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

const start = async () => {
  await getSetSubscription();
  setInterval(async () => {
    await getSetSubscription();
  }, 1000 * 60 * 5);
}

const startOffline = () => {
  setInterval(async () => {
    const userInfo = getUserInfo();

    if(!userInfo) return;

    const subscriptions = userInfo?.subscriptions;

    if (_.isEmpty(subscriptions) && _.last(_.split(window.location.href, '/')) !== 'upgrade') window.location.href = "/upgrade";
  }, 1000 * 5);
}

export const processor = {start, startOffline, instantUpdateSubscriptions: getSetSubscription};

const handleVisibilityChange = async () => {
  if (document.hasFocus()) {
    const userInfo = getUserInfo();
    if (!userInfo) return;
    await getSetSubscription();
  }
};

window.onfocus = handleVisibilityChange;