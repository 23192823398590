import {handleActions} from "redux-actions";

import {signInSuccess, signOutFailure, signOutSuccess, signOutRequest} from "../actions/User";

const initialState = {
  inSignedIn: false,
  signOutState: '',
};

export default handleActions(
  {
    [signInSuccess]: state => ({...state, inSignedIn: true}),
    [signOutSuccess]: state => ({...state, inSignedIn: false,}),
    [signOutRequest]: state => ({...state, signOutState: 'REQUEST'}),
    [signOutFailure]: state => ({...state, signOutState: 'FAILURE'}),
  },
  initialState
);
