import React from 'react';
import logo from './LogoMonsyn.png';
import './index.css';

const Logo = () => (
  <div className="logo">
    <a href='/'>
      <img src={logo} alt='Monsyn' />
    </a>
  </div>
);

export default Logo;
