const uuidv4 = require('uuid').v4;

import { SubscriptionTypes } from 'entities/subscription';
import { subscriptionsDB } from './pouchdb';

export default {
  loadAll
};

async function loadAll() {
  const subscriptions = await subscriptionsDB().allDocs({ include_docs: true });

  // Setup first trial
  if (subscriptions.total_rows === 0) {
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const nowTimestamp = new Date().getTime();
    const expireTimestamp = nowTimestamp + 7 * day;
    const id = uuidv4();

    const doc = {
      _id: id,
      start_date: nowTimestamp,
      end_date: expireTimestamp,
      type: SubscriptionTypes.trial,
      user: ''
    };

    await subscriptionsDB().put(doc);

    return {
      items: [doc],
      current: id
    };
  }

  // Find latest
  const currentSubscription = [...subscriptions.rows].sort((rowA, rowB) => {
    return rowB.doc.start_date > rowA.doc.start_date;
  })[0];

  return {
    current: currentSubscription.doc._id,
    items: subscriptions.rows.map((row) => {
      return {
        _id: row.doc._id,
        end_date: row.doc.end_date,
        start_date: row.doc.start_date,
        type: row.doc.type,
        user: row.doc.user
      };
    })
  };
}
