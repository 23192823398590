import {combineReducers} from 'redux';
import entities from './entities';
import ui from './ui';
import settings from './settings';
import user from './User';
import subscriptions from './subscriptions';

export default combineReducers({
    settings,
    entities,
    user,
    ui,
    subscriptions
});
