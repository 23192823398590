import {
  SubscriptionTypes,
  getCurrentSubscription
} from 'entities/subscription';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from "lodash";
import { useTranslation } from 'react-i18next';


const SubscriptionBadge = ({ subscriptions, isMobile }) => {
  if (!subscriptions?.current) {
    return null;
  }

  const { t } = useTranslation();
  const currentSubscription = getCurrentSubscription(subscriptions);

  if(_.some(subscriptions.items, (s) => s.plan === SubscriptionTypes.forever)) {
    return null;
  }

  return (
    <div className="subscription-badge">
      {!isMobile && <span>{t(`plans.${currentSubscription.plan}`)}</span>}
      {currentSubscription.plan === SubscriptionTypes.trial && <Link to='/upgrade' className="ui button">{t('buttons.btn_upgrade')}</Link>}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  subscriptions: state.subscriptions
});

export default connect(mapStateToProps)(SubscriptionBadge);
