import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import TransactionItem from './TransactionItem';
import ModalForm from './ModalForm';
import './index.css';
import { useTranslation } from 'react-i18next';

const TransactionList = ({
   isLoading,
   transactions,
   currentTransactionId,
   isModalOpen,
   isEdit,
   openTransactionInModal,
   resetTransactionForm,
   removeTransaction,
   EditForm
 }) => {
  const { t } = useTranslation();

  return(
    <Segment className="transactions-list__wrapper">
      <Dimmer inverted active={isLoading}>
        <Loader active inline="centered" />
      </Dimmer>
      {transactions.length > 0 ? (
        transactions.map(transaction => (
          <TransactionItem
            key={transaction.id}
            transaction={transaction}
            openTransactionInModal={openTransactionInModal}
          />
        ))
      ) : (
        <div className="transactions-list__empty">{t('transaction.transactions_list_empty')}</div>
      )}
      <ModalForm
        currentTransactionId={currentTransactionId}
        EditForm={EditForm}
        isOpen={isModalOpen}
        isEdit={isEdit}
        resetTransactionForm={resetTransactionForm}
        removeTransaction={removeTransaction}
      />
    </Segment>
  );
}

TransactionList.propTypes = {
  isLoading: PropTypes.bool,
  transactions: PropTypes.arrayOf(PropTypes.object),
  currentTransactionId: PropTypes.string,
  isModalOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  openTransactionInModal: PropTypes.func,
  resetTransactionForm: PropTypes.func,
  removeTransaction: PropTypes.func,
  EditForm: PropTypes.func
};

export default TransactionList;
