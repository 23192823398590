import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { setLanguage } from './actions/settings';

import enTranslation from './locales/en.json';
import frTranslation from './locales/fr.json';
import deTranslation from './locales/de.json';
import esTranslation from './locales/es.json';
import ptTranslation from './locales/pt.json';
import uaTranslation from './locales/ua.json';
import itTranslation from './locales/it.json';
import nlTranslation from './locales/nl.json';
import jpTranslation from './locales/jp.json';
import cnTranslation from './locales/cn.json';
import trTranslation from './locales/tr.json';
import seTranslation from './locales/se.json';
import plTranslation from './locales/pl.json';
import czTranslation from './locales/cz.json';
import krTranslation from './locales/kr.json';
import idTranslation from './locales/id.json';
import vnTranslation from './locales/vn.json';
import noTranslation from './locales/no.json';
import saTranslation from './locales/sa.json';
import ilTranslation from './locales/il.json';


const storedLanguage = localStorage.getItem('selectedLanguage') || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      fr: { translation: frTranslation },
      de: { translation: deTranslation },
      es: { translation: esTranslation },
      pt: { translation: ptTranslation },
      ua: { translation: uaTranslation },
      it: { translation: itTranslation },
      nl: { translation: nlTranslation },
      jp: { translation: jpTranslation },
      cn: { translation: cnTranslation },
      tr: { translation: trTranslation },
      se: { translation: seTranslation },
      pl: { translation: plTranslation },
      cz: { translation: czTranslation },
      kr: { translation: krTranslation },
      id: { translation: idTranslation },
      vn: { translation: vnTranslation },
      no: { translation: noTranslation },
      sa: { translation: saTranslation },
      il: { translation: ilTranslation },
    },
    lng: storedLanguage, // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

setLanguage(storedLanguage);

export default i18n;
