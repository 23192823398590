import {all} from 'redux-saga/effects';

import user from './user';
import accounts from './accounts';
import bootstrap from './bootstrap';
import dataImport from './dataImport';
import report from './report';
import settings from './settings';
import sync from './sync';
import tags from './categories';
import transactions from './transactions';
// import subscriptions from './subscriptions';

export default function* rootSaga() {
  yield all([
    ...user,
    ...accounts,
    ...bootstrap,
    ...dataImport,
    ...report,
    ...settings,
    ...sync,
    ...tags,
    ...transactions,
    // ...subscriptions
  ]);
}
