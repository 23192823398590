import { SubscriptionTypes } from 'entities/subscription';
import { connect } from 'react-redux';
import { useState } from 'react';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import _ from 'lodash';
import { postCancelSubscription } from '../../util/api';
import { processor } from '../../services/processor';
import Modal from '../../components/Modals/Modal/Modal';
import "./index.css";
import {useTranslation} from 'react-i18next';

const Subscriptions = ({ subscriptions, user }) => {
  if (!user?.inSignedIn) {
    return null;
  }

  const [cancelSubscriptionId, setCancelSubscriptionId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [modalMessageError, setModalMessageError] = useState("");
  const { t } = useTranslation();

  const handleConfirmAction = async () => {
    const [err, res] = await postCancelSubscription(cancelSubscriptionId);

    if (err) {
      setIsModalOpen(true);
      setModalMessageError(err.message);
    } else {
      setCancelSubscriptionId('');
      setSuccessModalOpen(true);

      await processor.instantUpdateSubscriptions();
    }
  };

  return (
    <>
      <div className='container-full-page subscription-managment'>
        <h1 className='subscription-managment__title'>{t('subscriptions.subscriptions_management')}</h1>
        <div className='subscription-managment__subscriptions'>
          <div className="table-wrapper">
            <table cellSpacing="0">
              <thead>
              <tr>
                <th>{t('subscriptions.subscription')}</th>
                <th>{t('subscriptions.date_start')}</th>
                <th>{t('subscriptions.date_end')}</th>
                <th>{t('subscriptions.next_charge_amount')}</th>
                <th>{t('subscriptions.next_charge_date')}</th>
                <th>{t('subscriptions.actions')}</th>
              </tr>
              </thead>
              <tbody>
              {
                _.map(_.filter(subscriptions?.items, (i) => i.isActive), (s) => {
                  return (
                    <tr>
                      <td>{t(`plans.${s.plan}`)}</td>
                      <td>{`${new Date(s?.createdAt?.date).getFullYear()}/${new Date(s?.createdAt?.date).getMonth() + 1}/${new Date(s?.createdAt?.date).getDate()}`}</td>
                      <td>{s.plan !== SubscriptionTypes.forever ? `${new Date(s?.expiredAt?.date).getFullYear()}/${new Date(s?.expiredAt?.date).getMonth() + 1}/${new Date(s?.expiredAt?.date).getDate()}` : "Forever"}</td>
                      <td>{s.nextChargeAmount !== "No further invoice" ? `${s.nextChargeAmount}$` : t(`messages.${s.nextChargeAmount}`)}</td>
                      <td>{!_.isEmpty(s.nextChargeDate) ? `${new Date(s?.nextChargeDate?.date).getFullYear()}/${new Date(s?.nextChargeDate?.date).getMonth() + 1}/${new Date(s?.nextChargeDate?.date).getDate()}` : ''}</td>
                      <td>
                        {_.isEmpty(s?.cancelAt) && s?.plan !== SubscriptionTypes.trial && s?.plan !== SubscriptionTypes.forever ?
                          <button onClick={() => setCancelSubscriptionId(s?.subscriptionId)}>{t('subscriptions.btn_cancel_subscription')}</button> : null}
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ConfirmModal isOpen={!!cancelSubscriptionId} onClose={() => setCancelSubscriptionId('')}
                    onConfirm={handleConfirmAction} />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} text={modalMessageError} color={"red"} />
      <Modal isOpen={successModalOpen} onClose={() => setSuccessModalOpen(false)}
             text={"Subscription was successfully deleted!"} color={"green"} />
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  subscriptions: state.subscriptions,
  user: state.user
});

export default connect(mapStateToProps)(Subscriptions);
