import { createActions } from 'redux-actions';

export const {
  loadTags,
  loadExpenseTagsSuccess,
  loadIncomeTagsSuccess,
  addTag,
  addTagInSettings,
  addTagSuccessInSettings,
  addTagFailInSettings,
  deleteTagInSettings,
  deleteTagSuccessInSettings,
  deleteTagFailInSettings

} = createActions(
  'LOAD_TAGS',
  'LOAD_EXPENSE_TAGS_SUCCESS',
  'LOAD_INCOME_TAGS_SUCCESS',
    'ADD_TAG',
    'ADD_TAG_IN_SETTINGS',
    'ADD_TAG_SUCCESS_IN_SETTINGS',
    'ADD_TAG_FAIL_IN_SETTINGS',
    'DELETE_TAG_IN_SETTINGS',
    'DELETE_TAG_SUCCESS_IN_SETTINGS',
    'DELETE_TAG_FAIL_IN_SETTINGS'
);
