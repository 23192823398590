import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'semantic-ui-react';
import { DropdownOption } from '../../types';
import Currency from '../../../entities/Currency';
import { useTranslation } from 'react-i18next';

const ExchangeRate = props => {
    const { t } = useTranslation();

    return (
      <Form.Group className="exchangeRate" >
          <Form.Field width={5} className="mobile-with-margin input-right no-label">
              <span>{t('transaction.exchange_rate')}</span>
              <Input
                required
                type="number"
                min={Currency.minAmount(props.currency)}
                step={Currency.minAmount(props.currency)}
                value={props.exchangeRate}
                onChange={props.onChangeExchangeRate}
                labelPosition="right"
                label={props.currency + "/" + props.linkedCurrency}
              />
          </Form.Field>
      </Form.Group>
    );
}

ExchangeRate.propTypes = {
    label: PropTypes.string,
    accountId: PropTypes.string,
    accountOptions: PropTypes.arrayOf(DropdownOption),
    amount: PropTypes.string,
    currency: PropTypes.string,
    currencyOptions: PropTypes.arrayOf(DropdownOption),
    onAccountChange: PropTypes.func,
    onAmountChange: PropTypes.func,
    onCurrencyChange: PropTypes.func
};

export default ExchangeRate;
