import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form } from 'semantic-ui-react';
import Currency from '../../../entities/Currency';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.options = Currency.options();
    this.updateSecondaryOptions(props.base);
  }

  componentWillReceiveProps(props) {
    this.updateSecondaryOptions(props.base);
  }

  updateSecondaryOptions(base) {
    this.secondaryOptions = this.options.filter(option => option.key !== base);
  }

  handleBaseChange = (event, { value }) => {
    this.props.changeSettingsCurrency({
      base: value,
      secondary: this.props.secondary
    });
  };

  handleSecondaryChange = (event, { value }) => {
    this.props.changeSettingsCurrency({
      base: this.props.base,
      secondary: value
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('settings.base_currency')}</label>
            <Dropdown
              search
              selection
              onChange={this.handleBaseChange}
              options={_.map(this.options, (c) => ({...c, text: `${c.text.split(',')[0]}, ${t(`currency.${c.text.split(', ')[1]}`)}`}))}
              value={this.props.base}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('settings.additional_currencies')}</label>
            <Dropdown
              placeholder={t('settings.placeholder_additional_currencies')}
              search
              selection
              multiple
              renderLabel={item => item.key}
              closeOnChange
              onChange={this.handleSecondaryChange}
              options={_.map(this.secondaryOptions, (c) => ({...c, text: `${c.text.split(',')[0]}, ${t(`currency.${c.text.split(', ')[1]}`)}`}))}
              value={this.props.secondary}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

Input.propTypes = {
  base: PropTypes.string,
  secondary: PropTypes.arrayOf(PropTypes.string),
  changeSettingsCurrency: PropTypes.func
};

export default withTranslation() (Input);
