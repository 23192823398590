import {useTranslation} from 'react-i18next';

const Redirect = () => {
  const { t } = useTranslation();

  return (
    <div className="container-redirect">
      <a className="ui button primary" href="/?close=1">
        {t('subscriptions.redirect_back_button')}
      </a>
    </div>
  );
};

export default Redirect;
