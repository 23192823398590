import React from 'react';
import { Menu } from 'semantic-ui-react';
import { getKindLabel, TransationKindT } from 'entities/Transaction';
import { useTranslation } from 'react-i18next';

const Header = ({ withTransfer, activeKind, changeKind }) => {
  const { Expense, Transfer, Income } = TransationKindT;
  const kinds = withTransfer ? [Expense, Transfer, Income] : [Expense, Income];
  const { t } = useTranslation();

  return (
    <Menu attached="top" widths={kinds.length}>
      {kinds.map(kind => (
        <Menu.Item
          key={kind}
          color={kind === Expense ? 'red' : kind === Income ? 'green' : 'black'}
          name={t(`transaction.${getKindLabel(kind)}`)}
          active={kind === activeKind}
          onClick={() => changeKind(kind)}
        />
      ))}
    </Menu>
  );
};

export default Header;
