import axios from 'axios';
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const callSafe = async (method, ...args) => {
  try {
    const result = await method(...args);
    return [null, result.data];
  } catch (e) {
    return [e, null];
  }
};

export const getCurrentUserInfo = () => {
  return callSafe(axios.get, `${process.env.REACT_APP_API_URL}/user/${userInfo.email}`, {
    headers: {
      Authorization: `Bearer ${userInfo.accessToken}`
    }
  });
}

export const postCancelSubscription = (subscriptionId) => {
  const formData = new FormData();
  formData.append("subscriptionId", subscriptionId);

  return callSafe(axios.post, `${process.env.REACT_APP_API_URL}/cancel-subscription`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${userInfo.accessToken}`,
        "Content-Type": 'multipart/form-data'
      }
  })
}

export const postSentEmail = (email) => {
  const formData = new FormData();
  formData.append("EmailAddress", email);

  return callSafe(axios.post, `${process.env.REACT_APP_API_URL}/reset-password`,
    formData,
    {
      headers: {
        "Content-Type": 'multipart/form-data'
      }
    })
}

export const postResetPassword = (newPassword, passwordResetToken) => {
  const formData = new FormData();
  formData.append("NewPassword", newPassword);
  formData.append("PasswordResetToken", passwordResetToken);

  return callSafe(axios.post, `${process.env.REACT_APP_API_URL}/reset-password-submit`,
    formData,
    {
      headers: {
        "Content-Type": 'multipart/form-data'
      }
    })
}

export const deletePermanentlyRemoveAccount = () => {
  return callSafe(axios.delete, `${process.env.REACT_APP_API_URL}/user/${userInfo.email}`, {
    headers: {
      Authorization: `Bearer ${userInfo.accessToken}`
    }
  })
}