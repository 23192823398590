import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;  
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;  
`;

export const CloseButton = styled.button`
  color: black;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: transparent;
  font-weight: bold;
  border: 0;
`;

