import {createActions} from 'redux-actions';

export const {
  signInSuccess,
  signOutRequest,
  signOutSuccess,
  signOutFailure,
} = createActions(
  'SIGN_IN_SUCCESS',
  'SIGN_OUT_REQUEST',
  'SIGN_OUT_SUCCESS',
  'SIGN_OUT_FAILURE',
);
