import {takeLatest} from 'redux-saga/effects';

import {bootstrap} from '../actions/app';
import {isUserLoggedIn} from './user';
import {loadSetting} from './settings';
import {syncSaga} from './sync';
import { loadSubscriptions } from './subscriptions';

export function* bootstrapSaga() {
  yield isUserLoggedIn();
  // yield loadSubscriptions();
  yield loadSetting();
  yield syncSaga();
}

export default [takeLatest(bootstrap, bootstrapSaga)];
