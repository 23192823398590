import React from 'react';
import CurrencyInput from './Currency/Input';
import CurrenchExchangeRate from './Currency/ExchangeRate';
// import DataExport from './DataExport';
// import DataImport from './DataImport';
import User from './User';
import CollapsibleSection from '../../components/CollapsibleSection';
import CategoriesEditContainer from "../../components/Settings/CategoriesEditContainer";
import {useTranslation} from 'react-i18next';

const Settings = () => {
  const { t } = useTranslation();
  return(
    <div className="container-full-page mt-settings">
      <CollapsibleSection name="settings_currency" label={t('settings.currency')}>
        <CurrencyInput />
        <CurrenchExchangeRate />
      </CollapsibleSection>
      {/*<CollapsibleSection name="settings_import" label="Data Import">*/}
      {/*  <DataImport />      */}
      {/*</CollapsibleSection>*/}
      <CollapsibleSection name="categories_edit" label={t('labels.categories')}>
        <CategoriesEditContainer />
      </CollapsibleSection>
      {/*<CollapsibleSection name="settings_export" label="Data Export">*/}
      {/*  <DataExport />*/}
      {/*  </CollapsibleSection>*/}
      <CollapsibleSection name="settings_user" label={t('settings.user')}>
        <User />
      </CollapsibleSection>
    </div>
  );
}

export default Settings;
