import ChartistGraph from '../../../util/react-chartist';
import Chartist from 'chartist';
import 'chartist-plugin-tooltips';
import 'chartist/dist/chartist.min.css';
import '../../../util/react-chartist/chartist-plugin-tooltip.css';
import PropTypes from 'prop-types';
import React from 'react';
import abbreviate from 'number-abbreviate';

class ExpenseCategories extends React.Component {
  render() {
    const options = {
      axisY: {
        offset: 70
      },
      axisX: {
        offset: 11,
        onlyInteger: true,
        labelInterpolationFnc: (value) => abbreviate(value, 2)
      },
      chartPadding: {
        right: 45,
        left: 30
      },
      reverseData: true,
      horizontalBars: true,
      plugins: [
        Chartist.plugins.tooltip({
          class: 'mono',
          currency: this.props.currency,
          anchorToPoint: true
        })
      ]
    };

    return (
      <ChartistGraph
        type="Bar"
        className="mt-report-expense-tags"
        style={{ height: `${this.props.data.labels.length * 4}em` }}
        data={this.props.data}
        options={options}
      />
    );
  }
}

ExpenseCategories.propTypes = {
  currency: PropTypes.string,
  data: PropTypes.shape({
    labels: PropTypes.array,
    series: PropTypes.array
  })
};

export default ExpenseCategories;
