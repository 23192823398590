import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm, Message} from 'semantic-ui-react';
import Header from "../Transaction/Form/Header";
import {useTranslation} from 'react-i18next';

const CategoriesEdit = (props) => {
    const { t } = useTranslation();
    const initOptionsState = [{}];
    const [tagsOptions, newTagsOptions] = useState(initOptionsState);
    useEffect (() => {
        newTagsOptions(props.props.tagsOptions)
    }, [props.props.tagsOptions])

    const [newItem, changeNewItem] = useState("");
    const [confirmDelete, changeConfirmDelete] = useState(false);
    const [confirmAdd, changeConfirmAdd] = useState(false);
    const [keyEdit, changeKeyEdit] = useState(null);
    const [confirmContent, changeConfirmContent] = useState("");

    const deleteItem = (key)=> {
        changeConfirmDelete(true);
        changeKeyEdit(key)
    }
    const deleteItemCancel = ()=> {
        changeConfirmDelete(false);
    }
    const deleteItemConfirm = ()=> {
        if (keyEdit === "Undefined-Expense" || keyEdit === "Undefined-Income") {
            changeConfirmContent( keyEdit  + ` ${t('messages.category_cannot_be_deleted')} `)
            return
        }

        props.onDeleteItem(keyEdit);
        changeConfirmDelete(false);
    }
    const addItemCancel =()=> {
        changeConfirmAdd(false);
    }
    const addItemConfirm = ()=> {
        const newCategoryObject={
            key: newItem,
            value: newItem,
            text: newItem
        }
        const foundItem = tagsOptions.some((el) => el.key === newCategoryObject.key);
        if (foundItem)  {
            changeConfirmContent(`${t('transaction.category')} ` + newItem +  ` ${t('messages.already_exists')}`);
            return
        }
        props.onAddItem( newItem);
        changeConfirmAdd(false);
        changeNewItem('');
    }
    const addItem = ()=> {
        if(!newItem) return;

        changeConfirmAdd(true);
    }
    const changeKind=(e)=>{
        props.props.changeKind(e);
    }

    return (
      <React.Fragment>
          <Header
            //withTransfer={!!props.form.linkedAccountId}
            activeKind={props.props.activeKind}
            changeKind={changeKind}
          />
          {tagsOptions && tagsOptions.map(option => {
              return (
                <React.Fragment key={option.key}>
                    <div key={option.key} className="categories  ui menu dropdown">
                        <div className="item">{option.value === 'Undefined-Expense' || option.value === 'Undefined-Income' ? t(`transaction.${option.value}`) : option.value}</div>
                        <div className="item-delete">
                            <i aria-hidden="true" className="delete icon" onClick={() => deleteItem(option.key)}></i>
                        </div>
                    </div>
                </React.Fragment>
              )
          })}
          <div className="ui add multiple selection dropdown">
              <div role="combobox" className="ui search selection dropdown">
                  <input autoComplete="off" className="search" placeholder={t('settings.add_new_category')} type="text"
                         onChange={(e) => changeNewItem(e.target.value)} value={newItem}
                  />
              </div>
              <div className="transaction-form-grid__field">
                  <Button
                    fluid onClick={() => addItem()}
                  >
                      {t('settings.add_new_category')}
                  </Button>
              </div>
          </div>
          <Confirm content={`${t('messages.are_you_sure_you_want_to_delete')} ` + keyEdit + ` ${t('transaction.category')}?`} open={confirmDelete}
                   onCancel={() => deleteItemCancel()}
                   onConfirm={() => deleteItemConfirm()}
          />
          <Confirm content={`${t('messages.are_you_sure_you_want_to_add')} ` + newItem + ` ${t('transaction.category')}?`} open={confirmAdd}
                   onCancel={() => addItemCancel()}
                   onConfirm={() => addItemConfirm()}
          />
          <Confirm content={confirmContent} open={!!confirmContent}
                   onCancel={() => {
                       changeConfirmContent("");
                       changeConfirmAdd(false);
                       changeConfirmDelete(false);
                   }}
                   onConfirm={() => {
                       changeConfirmContent("");
                       changeConfirmAdd(false);
                       changeConfirmDelete(false);
                   }}
          />
          <Confirm content={`${t('transaction.category')} ` + `${newItem}` + ` ${t('messages.is_been_deleted')}`} open={props.loading} />
      </React.Fragment>
    );
}

CategoriesEdit.propTypes = {
    onAddItem: PropTypes.func,
};

export default CategoriesEdit;
