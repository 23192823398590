import LoginButton from 'components/LoginButton';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import './index.css';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import { useState } from 'react';
let inAppBrowserRef;

const Upgrade = ({ user }) => {
  const { t } = useTranslation();
  const [cordovaProcess, setCordovaProcess] = useState(false);

  if (!user.inSignedIn) {
    return (
      <Message warning size="large">
        <Message.Header>{t('messages.please_login')}</Message.Header>
        <Message.Content>
          <LoginButton />
        </Message.Content>
      </Message>
    );
  }

  const loadStartCallBack = (event) => {
    if (event.url.includes('close=1')) {
      setCordovaProcess(true);
      inAppBrowserRef.close();
      inAppBrowserRef = null;
      window.location.href = '/';
    }
  };

  const handleClick = (url) => {
    if (window.cordova) {
      inAppBrowserRef = window.cordova.InAppBrowser.open(url, '_blank');
      inAppBrowserRef.addEventListener('loadstart', loadStartCallBack);
    } else {
      window.open(url, '_self');
    }
  };

  if (cordovaProcess) {
    return <Loader active/>;
  }

  return (
    <div className="mt-6">
      <div className="ui grid stackable container upgradeContent">
        <div className="three column row">
          <div className="column">
            <div className="ui card fluid">
              <div className="content">
                <div className="header">{t('plans.Monthly')}</div>
              </div>
              <div className="content">
                <h2 className="ui header">$1</h2>
              </div>
              <div className="extra content">
                <button
                  className="ui button primary"
                  onClick={() =>
                    handleClick(process.env.REACT_APP_PAYMENT_LINK_MONTHLY)
                  }
                >
                  {t('buttons.btn_upgrade')}
                </button>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="ui card fluid">
              <div className="content">
                <div className="header">{t('plans.Yearly')}</div>
              </div>
              <div className="content">
                <h2 className="ui header">$10</h2>
              </div>
              <div className="extra content">
                <button
                  className="ui button primary"
                  onClick={() =>
                    handleClick(process.env.REACT_APP_PAYMENT_LINK_YEARLY)
                  }
                >
                  {t('buttons.btn_upgrade')}
                </button>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="ui card fluid">
              <div className="content">
                <div className="header">{t('plans.Forever')}</div>
              </div>
              <div className="content">
                <h2 className="ui header">$100</h2>
              </div>
              <div className="extra content">
                <button
                  className="ui button primary"
                  onClick={() =>
                    handleClick(process.env.REACT_APP_PAYMENT_LINK_FOREVER)
                  }
                >
                  {t('buttons.btn_upgrade')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  subscriptions: state.subscriptions,
  user: state.user
});

export default connect(mapStateToProps)(Upgrade);
