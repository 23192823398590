import {call, put, takeLatest} from 'redux-saga/effects';

import {signOutFailure, signInSuccess, signOutSuccess, signOutRequest} from '../actions/User';

import AccountsStorage from "../util/storage/accounts";
import SettingsStorage from "../util/storage/settings";
import TagsStorage from "../util/storage/categories";
import TransactionsStorage from "../util/storage/transactions";

import {loadSetting} from "./settings";

export function* isUserLoggedIn() {
  const userInfo = yield call([localStorage, 'getItem'], 'userInfo');
  if (userInfo) yield put(signInSuccess());
}

function* signOutSaga() {
  try {
    yield call(AccountsStorage.destroy);
    yield call(SettingsStorage.destroy);
    yield call(TagsStorage.destroy);
    yield call(TransactionsStorage.destroy);
    yield call([localStorage, 'clear']);

    yield put(signOutSuccess());
    yield loadSetting();
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export default [takeLatest(signOutRequest, signOutSaga)];
