import { loadSubscriptionsSuccess } from 'actions/subscriptions';
import { handleActions } from 'redux-actions';
import _ from 'lodash';

const initialState = {
  items: [],
  current: null
};

export default handleActions(
  {
    [loadSubscriptionsSuccess]: (state, action) => {
      const subscriptions = action?.payload || [];
      const currentSubscriptions = _.filter(subscriptions, { isActive: true });

      return {
        items: subscriptions,
        current: currentSubscriptions[currentSubscriptions.length - 1]
      };
    }
  },
  initialState
);
