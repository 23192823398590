import React from 'react';
import PropTypes from 'prop-types';
import ChartistGraph from '../../../util/react-chartist';
import Chartist from 'chartist';
import abbreviate from 'number-abbreviate';
import 'chartist/dist/chartist.min.css';
import 'chartist-plugin-tooltips';
import '../../../util/react-chartist/chartist-plugin-tooltip.css';
import { Statistic } from 'semantic-ui-react';
import Amount from '../../Amount';
import Currency from '../../../entities/Currency';
import { withTranslation } from 'react-i18next';


class ExpenseIncome extends React.Component {
  render() {
    const options = {
      axisY: {
        labelInterpolationFnc: (value) => abbreviate(value, 2)
      },
      plugins: [
        Chartist.plugins.tooltip({
          class: 'mono',
          currency: this.props.currency,
          anchorToPoint: true
        })
      ]
    };

    let responsiveOptions = [
      [
        'screen and (max-width: 520px)',
        {
          axisX: {
            labelInterpolationFnc: (value, index) => {
              if (
                index % Math.ceil(this.props.data.labels.length / 20) === 0
              ) {
                return value;
              } else {
                return null;
              }
            }
          }
        }
      ]
    ];

    const { t } = this.props;
    const { data, base } = this.props;
    const income = data.series ? data.series[0].filter((val) => val !== 0) : [];
    const expense = data.series
      ? data.series[1].filter((val) => val !== 0)
      : [];

    return (
      <React.Fragment>
        <div className="mt-report-stats">
          <Statistic.Group size="mini">
            <Statistic>
              <Statistic.Value>
                <Amount
                  code={base}
                  value={Currency.numberToCents(
                    income.reduce((sum, cur) => sum + cur, 0),
                    base
                  )}
                  showCents={false}
                />
              </Statistic.Value>
              <Statistic.Label>{t('labels.total_income')}</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>
                <Amount
                  code={base}
                  value={
                    Currency.numberToCents(
                      expense.reduce((sum, cur) => sum + cur, 0),
                      base
                    ) * -1
                  }
                  showCents={false}
                />
              </Statistic.Value>
              <Statistic.Label>{t('labels.total_expense')}</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </div>
        <ChartistGraph
          type="Bar"
          className="ct-octave mt-report-expense-income"
          data={data}
          options={options}
          responsiveOptions={responsiveOptions}
        />
      </React.Fragment>
    );
  }
}

ExpenseIncome.propTypes = {
  currency: PropTypes.string,
  data: PropTypes.shape({
    labels: PropTypes.array,
    series: PropTypes.array,
  }),
};

export default withTranslation() (ExpenseIncome);