import {Button} from 'semantic-ui-react';
import React from 'react';
import { Link } from 'react-router-dom'
import {useTranslation} from 'react-i18next';

const LoginButton = () => {
  const { t } = useTranslation();

  return <Button as={Link} to="/login">{t('authorisation.btn_login')}</Button>;
};

export default LoginButton;
