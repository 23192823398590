import {handleActions} from 'redux-actions';

import {
  loadExpenseTagsSuccess,
  loadIncomeTagsSuccess,
  addTagInSettings,
  addTagSuccessInSettings,
  addTagFailInSettings,
  deleteTagInSettings,
  deleteTagSuccessInSettings,
  deleteTagFailInSettings
} from '../../actions/entities/categories';
import {addTag } from '../../actions/ui/form/transaction';
import {signOutSuccess} from '../../actions/User';
import {TransationKindT} from '../../entities/Transaction';

const {Expense, Transfer, Income} = TransationKindT;

const initialState = {
    [Expense]: ["Undefined-Expense"],
    [Transfer]: [],
    [Income]: ["Undefined-Income"],
    loading: false,
    error: "Empty"
};

export default handleActions(
    {
        [loadExpenseTagsSuccess]: (state, {payload}) => ({
            ...state,
            [Expense]: payload.length > 0 ? payload.includes("Undefined-Expense") ? payload : ["Undefined-Expense", ...payload] :  initialState[Expense]
        }),
        [loadIncomeTagsSuccess]: (state, {payload}) => ({
            ...state,
            [Income]: payload.length > 0 ? payload.includes("Undefined-Income") ? payload : ["Undefined-Income", ...payload] : initialState[Income]
        }),
        [addTag]: (state, action) => {
            const {kind, tag} = action.payload;
            return state[kind].includes(tag)
                ? state
                : {...state, [kind]: state[kind].concat(tag)};
        },
        [addTagInSettings]: (state, action) => {
            return {...state, loading: true};
        },
        [addTagSuccessInSettings]: (state, action) => {
            const {kind, tags} = action.payload;
            return state[kind].includes(tags)
                ? state
                : {...state, [kind]: state[kind].concat(tags), loading: false};
        },
        [addTagFailInSettings]: (state, action) => {
            return { ...state, loading: false, error: "Tag is not deleted."}
        },
        [deleteTagInSettings]: (state, action) => {
            return {...state, loading: true, error: "Tag is not deleted."};
        },
        [deleteTagSuccessInSettings]: (state, action) => {
            const {kind, tags} = action.payload;
            const newState = state[kind].filter(id => id !== action.payload.tags);
            return state[kind].includes(tags)
                ? {...state, [kind]: newState, loading: false, error: "New error2"}
                : {...state, loading: false, error: "New error3"}
        },
        [deleteTagFailInSettings]: (state, action) => {
            return {...state, loading: false, error: "Tag is not deleted."}
        },

        [signOutSuccess]: () => initialState,
    },
    initialState
);
