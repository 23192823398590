import _ from 'lodash';
export const SubscriptionTypes = {
  trial: 'Trial',
  monthly: 'Monthly',
  yearly: 'Yearly',
  forever: 'Forever'
};

export const getCurrentSubscription = (subscriptions) => {
  const createdTime = subscriptions?.current?.createdAt?.date;
  const expiredTime = subscriptions?.current?.expiredAt?.date;

  return _.extend(subscriptions?.current, {createdTime, expiredTime});
};

export const isNotExpire = (subscriptions) => {
  const current = getCurrentSubscription(subscriptions);
  const nowTimestamp = new Date().getTime();
  const diffTimestamp = Math.abs(current?.end_date - nowTimestamp) / 1000;

  return diffTimestamp > 0;
};
