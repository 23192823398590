import React from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';

import App from './App';
import ErrorBoundary from 'components/Sentry/ErrorBoundary';
import { I18nextProvider } from 'react-i18next';
import i18n from  '../i18n';

const Root = ({store, history}) => (
  <ErrorBoundary>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App history={history}/>
      </Provider>
    </I18nextProvider>
  </ErrorBoundary>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Root;
