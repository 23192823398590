import { arSA, cs, de, enUS, es, fr, he, id, it, ja, ko, nl, nn, pl, pt, se, tr, uk, vi, zhCN } from 'date-fns/locale';

export const savedLanguage = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('selectedLanguage');
  }
}

export const languagesMapper = {
  cn: zhCN,
  cz: cs,
  de: de,
  en: enUS,
  es: es,
  fr: fr,
  id: id,
  il: he,
  it: it,
  jp: ja,
  kr: ko,
  nl: nl,
  no: nn,
  pl: pl,
  pt: pt,
  sa: arSA,
  se: se,
  tr: tr,
  ua: uk,
  vn: vi
}