import React, { useState } from 'react';
import useInput from '../../util/use-input';
import { Redirect } from 'react-router-dom';
import "./index.css";
import { postResetPassword } from '../../util/api';
import Modal from '../../components/Modals/Modal/Modal';
import { withRouter  } from 'react-router-dom';


const validatePassword = (password) => {
  return String(password).trim().length >= 5;
}

const ResetPassword = ({match, history}) => {
  const { passwordResetToken } = match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [failedModalOpen, setFailedModalOpen] = useState(false);
  const [modalMessageError, setModalMessageError] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    changeHandler: passwordChangeHandler,
    blurHandler: passwordBlurHandler,
    reset: resetPassword,
  } = useInput(validatePassword);

  let isFormValid = false;
  if (passwordIsValid) {
    isFormValid = true;
  }

  const handleResetPassword = async (ev) => {
    ev.preventDefault();

    if (!isFormValid) {
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setIsServerError(false);
    setServerErrorMessage("");

    try {
      const [err, res] = await postResetPassword(passwordValue, passwordResetToken);

      if (err) {
        setIsLoading(false);
        setFailedModalOpen(true);
        setModalMessageError(err.response.data.message);
      } else {
        setIsLoading(false);
        resetPassword();
        setSuccessModalOpen(true);
        setTimeout(() => {
          return history.push("/login");
        }, 5000);
      }
    } catch (error) {
      setServerErrorMessage('An unexpected error occurred.');
    }
  }

  return (
    <div className={"container-reset-password"}>
      <div className="form-wrapper">
        {isLoading && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        {isServerError && (
          <div className="ui warning message">
            <div className="header">
              {serverErrorMessage ? serverErrorMessage : "Something go wrong, please try later"}
            </div>
          </div>
        )}
        <form className="ui form" onSubmit={handleResetPassword}>
          <div className={`field ${passwordHasError && "error"}`}>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="******"
              value={passwordValue}
              onChange={passwordChangeHandler}
              onBlur={passwordBlurHandler}
            />
            {passwordHasError && <p className="error-label">Passwords must be at least 5 characters.</p>}
          </div>
          <button className="ui button primary" type="submit">
            Reset password
          </button>
        </form>
      </div>
      <Modal isOpen={failedModalOpen} onClose={() => setFailedModalOpen(false)} text={modalMessageError} color={"red"} />
      <Modal isOpen={successModalOpen} onClose={() => {setSuccessModalOpen(false); history.push("/login")}} text={"Your password has been successfully reset!"} color={"green"} />
    </div>
  );
};

export default withRouter(ResetPassword);