import { createBrowserHistory, createHashHistory } from 'history';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import 'semantic-ui-css/semantic.min.css';
import Root from './containers/Root';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './store/configureStore';

export const store = configureStore();

const renderReactDOM = (history) => {
  Sentry.init({
    dsn: 'https://5ae855d4c1d840c1b06679123069574f@sentry.io/1335198'
  });

  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<Root store={store} history={history} />);

  registerServiceWorker();

  if (module.hot) {
    module.hot.accept();
  }
};

if (window.cordova) {
  document.addEventListener(
    'deviceready',
    () => {
      renderReactDOM(createHashHistory());
    },
    false
  );
} else {
  renderReactDOM(createBrowserHistory());
}
