import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import Amount from '../../Amount';
import { useTranslation } from 'react-i18next';

const Footer = ({ base, income, expense }) => {
  const { t } = useTranslation();
  const savedLanguage = localStorage.getItem('selectedLanguage');

  return(
    <div className="transactions-list-footer">
      <Table fixed basic="very" unstackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{t('labels.total_income')}</Table.Cell>
            <Table.Cell textAlign={savedLanguage === 'sa' || savedLanguage === 'il' ? "left" : "right"}>
              <Amount code={base} value={income} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('labels.total_expense')}</Table.Cell>
            <Table.Cell textAlign={savedLanguage === 'sa' || savedLanguage === 'il' ? "left" : "right"}>
              <Amount code={base} value={expense} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell />
            <Table.Cell textAlign={savedLanguage === 'sa' || savedLanguage === 'il' ? "left" : "right"}>
              <Amount code={base} value={income + expense} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}

Footer.propTypes = {
  base: PropTypes.string,
  income: PropTypes.number,
  expense: PropTypes.number
};

export default Footer;
