import React from 'react';
import PropTypes from 'prop-types';
import {Router, Route, Switch, Redirect, HashRouter} from 'react-router-dom';
import {Dimmer, Loader} from 'semantic-ui-react';
import {connect} from 'react-redux';
import throttle from 'lodash/throttle';
import routes from '../router/routes';
import InitialSetup from './InitialSetup';
import Login from './Login';
import SidebarMenu from './SidebarMenu';
import Header from '../components/Header';
import SyncWarning from './SyncWarning';
import RedirectRoute from './Redirect';
import {windowResize} from '../actions/ui/windowResize';
import {toggleSidebar} from '../actions/ui/sidebar';
import {bootstrap} from '../actions/app';
import {isSignedIn} from "../selectors/User";
import Upgrage from './Upgrage';
import { processor } from '../services/processor';
import ResetPassword from './ResetPassword';
import { withTranslation } from 'react-i18next';
import "../index_rtl.css";
import { savedLanguage } from '../util';


const RouterWrapper = ({children, ...props}) => {
  if (window.cordova) {
    return <HashRouter {...props}>{children}</HashRouter>
  }

  return <Router {...props}>{children}</Router>
};

class App extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', throttle(this.props.windowResize, 500));
    this.props.bootstrap();

    processor.start();
    processor.startOffline();
  }

  componentDidUpdate() {
    if (savedLanguage()) {
      if (savedLanguage() === 'sa' || savedLanguage() === 'il') {
        document.body.classList.add("body-rtl");
      } else {
        document.body.classList.remove("body-rtl");
      }
    }
  }

  render() {
    if (!this.props.isLoaded) {
      return (
        <Loader
          active
          content={
            this.props.isSyncRunning &&
            this.props.t('messages.is_sync_running')
          }
        />
      );
    }

    return (
      <RouterWrapper history={this.props.history}>
        <Switch>
          <Route key={'redirect'} path={'/redirect'} exact={true}>
            <RedirectRoute/>
          </Route>
          {!this.props.isAuthenticated && (
            <Route component={Login} exact={true} path="/login"/>
          )}
          {!this.props.isAuthenticated && (
            <Route component={ResetPassword} exact={true} path="/reset-password/code/:passwordResetToken"/>
          )}
          {!this.props.isSetupComplete ? (
            <Route component={InitialSetup}/>
          ) : (
            <React.Fragment>
              <Route render={this.renderNavigationRoutes} />

              <Route
                path="/upgrade"
                exact={true}
                render={(props) => (
                  <React.Fragment>
                    <Header label="Plans"/>
                    <div className="container">
                      <Upgrage />
                    </div>
                  </React.Fragment>
                )}
              />
            </React.Fragment>
          )}
        </Switch>
      </RouterWrapper>
    );
  }

  /**
   * Navigation routes are the pages associated to navigation menu items,
   * e.g. Dashboard, Transactions, Settings etc.
   * They are rendered with common structure: sidebar menu and sticky header.
   */
  renderNavigationRoutes = () => {
    if (window.location.pathname.endsWith('index.html') && !window.cordova) {
      return <Redirect to="/"/>;
    }
    const {isSidebarOpen, isMobile, toggleSidebar, subscriptions} = this.props;

    // const isSubscriptionValid = isNotExpire(subscriptions);

    // if (!isSubscriptionValid) {
    //   return <Redirect to="/upgrade" />;
    // }

    const translatedRoutes = routes.map(route => ({
      ...route,
      label: this.props.t(route.label),
    }));

    return (
      <React.Fragment>
        <Dimmer
          page
          active={isMobile && isSidebarOpen}
          onClick={toggleSidebar}
          style={{zIndex: 100}}
        />
        <SidebarMenu
          isOpen={!isMobile || isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        {flatten(translatedRoutes).map(route => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={props => (
              <React.Fragment>
                <Header label={route.label}/>
                <div className="container">
                  <SyncWarning/>
                  <route.component {...props} />
                </div>
              </React.Fragment>
            )}
          />
        ))}
      </React.Fragment>
    );
  };
}

function flatten(routes) {
  let flatRoutes = [];
  routes.forEach(route => {
    if (route.routes) {
      flatRoutes.push({...route, exact: true});
      flatRoutes.push(...flatten(route.routes));
    } else {
      flatRoutes.push(route);
    }
  });

  return flatRoutes;
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  isLoaded: PropTypes.bool,
  isSyncRunning: PropTypes.bool,
  isSetupComplete: PropTypes.bool,
  isMobile: PropTypes.bool,
  isSidebarOpen: PropTypes.bool,
  bootstrap: PropTypes.func,
  windowResize: PropTypes.func,
  toggleSidebar: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
  history: ownProps.history,
  isLoaded: state.settings.isLoaded,
  isSyncRunning: state.ui.sync.isRunning,
  isSetupComplete: state.settings.isSetupComplete,
  isMobile: state.ui.isMobile,
  isSidebarOpen: state.ui.isSidebarOpen,
  isAuthenticated: isSignedIn(state),
  subscriptions: state.subscriptions,
});

App = connect(
  mapStateToProps,
  {
    bootstrap,
    windowResize,
    toggleSidebar
  }
)(App);

export default withTranslation() (App)
