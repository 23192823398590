export const languages = [
  {
    flag: "us",
    key: "EN",
    text: "English",
    value: "en"
  },
  {
    flag: "de",
    key: "DE",
    text: "German",
    value: "de"
  },
  {
    flag: "fr",
    key: "FR",
    text: "French",
    value: "fr"
  },
  {
    flag: "es",
    key: "ES",
    text: "Spanish",
    value: "es"
  },
  {
    flag: "pt",
    key: "PT",
    text: "Portugal",
    value: "pt"
  },
  {
    flag: "ua",
    key: "UA",
    text: "Ukraine",
    value: "ua"
  },
  {
    flag: "it",
    key: "IT",
    text: "Italy",
    value: "it"
  },
  {
    flag: "nl",
    key: "NL",
    text: "Netherlands",
    value: "nl"
  },
  {
    flag: "jp",
    key: "JP",
    text: "Japan",
    value: "jp"
  },
  {
    flag: "cn",
    key: "CN",
    text: "China",
    value: "cn"
  },
  {
    flag: "tr",
    key: "TR",
    text: "Turkey",
    value: "tr"
  },
  {
    flag: "se",
    key: "SE",
    text: "Swedish",
    value: "se"
  },
  {
    flag: "pl",
    key: "PL",
    text: "Poland",
    value: "pl"
  },
  {
    flag: "cz",
    key: "CZ",
    text: "Czech",
    value: "cz"
  },
  {
    flag: "kr",
    key: "KR",
    text: "South Korean",
    value: "kr"
  },
  {
    flag: "id",
    key: "ID",
    text: "Indonesian",
    value: "id"
  },
  {
    flag: "vn",
    key: "VN",
    text: "Vietnamese",
    value: "vn"
  },
  {
    flag: "no",
    key: "NO",
    text: "Norwegian",
    value: "no"
  },
  {
    flag: "sa",
    key: "SA",
    text: "Arabic",
    value: "sa"
  },
  {
    flag: "il",
    key: "IL",
    text: "Hebrew",
    value: "il"
  }
]