import ChartistGraph from '../../../util/react-chartist';
import Chartist from 'chartist';
import 'chartist/dist/chartist.min.css';
import 'chartist-plugin-tooltips';
import '../../../util/react-chartist/chartist-plugin-tooltip.css';
import abbreviate from 'number-abbreviate';
import PropTypes from 'prop-types';
import React from 'react';


class NetWorth extends React.Component {
  render() {
    const options = {
      showArea: true,
      axisY: {
        labelInterpolationFnc: (value) => abbreviate(value, 2)
      },
      plugins: [
        Chartist.plugins.tooltip({
          class: 'mono',
          currency: this.props.currency,
          anchorToPoint: true
        })
      ]
    };

    let responsiveOptions = [
      ['screen and (max-width: 520px)', {
        axisX: {
          labelInterpolationFnc: (value, index) => {
            if (index % Math.ceil(this.props.data.labels.length / 20) === 0) {
              return value;
            } else {
              return '';
            }
          },
          labelOffset: {
            x: 0,
            y: 5
          }
        }
      }]
    ];


    return (
      <ChartistGraph
        type="Line"
        className="ct-octave mt-report-net-worth"
        data={this.props.data}
        options={options}
        responsiveOptions={responsiveOptions}
      />
    );
  }
}

NetWorth.propTypes = {
  currency: PropTypes.string,
  data: PropTypes.shape({
    labels: PropTypes.array,
    series: PropTypes.array
  })
};

export default NetWorth;
