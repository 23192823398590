import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import CollapsibleSection from '../../components/CollapsibleSection';
import NetWorth from './NetWorth';
import Accounts from './Accounts';
import TransactionForm from '../Transactions/Form';
import RecentTransactions from './RecentTransactions';
import { loadAccounts } from '../../actions/entities/accounts';
import { loadRecentTransactions } from '../../actions/entities/transactions';
import { loadTags } from '../../actions/entities/categories';
import { useTranslation } from 'react-i18next';

const Dashboard = ({ loadTags, loadAccounts, loadRecentTransactions }) => {
  useEffect(() => {
    loadTags();
    loadAccounts();
    loadRecentTransactions();
  }, [loadTags, loadAccounts, loadRecentTransactions]);

  const { t } = useTranslation();

  return (
    <div className="container-full-page">
      <Grid>
        <Grid.Row>
          <Grid.Column computer={6} mobile={16}>
            <CollapsibleSection
              name="net_worth"
              label={t('dashboard.net_worth')}
              LabelComponent={NetWorth}
            >
              <Accounts />
            </CollapsibleSection>
          </Grid.Column>
          <Grid.Column computer={10} mobile={16}>
            <CollapsibleSection name="add_tx" label={t('dashboard.new_transaction')}>
              <TransactionForm />
            </CollapsibleSection>
            <CollapsibleSection name="recent_tx" label={t('dashboard.recent_transactions')}>
              <RecentTransactions />
            </CollapsibleSection>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

Dashboard.propTypes = {
  loadTags: PropTypes.func,
  loadAccounts: PropTypes.func,
  loadRecentTransactions: PropTypes.func,
};

export default connect(
  undefined,
  {
    loadTags,
    loadAccounts,
    loadRecentTransactions,
  }
)(Dashboard);
