import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, Divider, Header} from 'semantic-ui-react';
import CurrencyInput from '../Settings/Currency/Input';
import CurrencyExchangeRate from '../Settings/Currency/ExchangeRate';
import AccountForm from '../Accounts/Form';
import AccountList from '../Accounts/List';
import {completeSetup} from '../../actions/settings';
import {loadAccounts} from '../../actions/entities/accounts';
import {getAccountsList} from '../../selectors/entities/accounts';
import {isSignedIn} from '../../selectors/User';
import LoginButton from 'components/LoginButton';
import { withTranslation } from 'react-i18next';

class InitialSetup extends React.Component {

  componentDidMount() {
    this.props.loadAccounts();
  }

  render() {
    const { t } = this.props;

    return (
      <div className="container-raised-desktop">
        <Header as="h2" icon="settings" content={t('initialSetup.monsyn_setup')} />
        <Divider/>
        {!this.props.isAuthenticated && (
          <p>
            <LoginButton/> {t('initialSetup.initial_setup_text')}
          </p>
        )}
        <Header as="h2">{t('initialSetup.currencies')}</Header>
        <p>
          {t('initialSetup.currencies_text')}
          <br/>
          {t('initialSetup.currencies_text2')}
        </p>
        <CurrencyInput/>
        <CurrencyExchangeRate/>
        <Header as="h2">{t('sidebarMenu.Accounts')}</Header>
        <p>
          {t('initialSetup.accounts_text')}
          <br/>
          {t('initialSetup.accounts_text2')}
        </p>
        <AccountForm/>
        {this.props.accounts.length > 0 && (
          <div style={{margin: '1em'}}>
            <AccountList/>
            <div className="form-submit">
              <Button
                primary
                content={t('buttons.btn_finish')}
                onClick={this.props.completeSetup}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

InitialSetup.propTypes = {
  isAuthenticated: PropTypes.bool,
  accounts: PropTypes.arrayOf(PropTypes.object),
  loadAccounts: PropTypes.func,
  completeSetup: PropTypes.func
};

const mapStateToProps = state => ({
  isAuthenticated: isSignedIn(state),
  accounts: getAccountsList(state)
});

InitialSetup = connect(
  mapStateToProps,
  {loadAccounts, completeSetup}
)(InitialSetup);

export default withTranslation() (InitialSetup);